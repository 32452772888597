import React, { useContext, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { graphql, Link } from "gatsby"
import Container from "@src/components/Container"
import Header from "@src/components/Header"
import Title from "@src/components/Title"
import Img from "gatsby-image"
import CarnetIcon from "@src/assets/icons/go-carnet.svg"
import DatasheetIcon from "@src/assets/icons/datasheet.inline.svg"
import { linkStyle } from "@src/components/Link"
import { LoadingContext } from "@src/contexts/LoadingContext"

const IndexContainer = styled.article`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const IndexHeader = styled(Header)`
  width: 100%;
`

const IndexMain = styled(Container)`
  flex: 1 0 0;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    padding-bottom: 0;
  }
`

const IndexMenu = styled(Container)`
  width: 100%;

  &:before {
    content: "";
    display: block;
    width: 100%;
    border-top: ${props => props.theme.border};
  }
`

const IndexMenuButton = styled.a`
  position: relative;
  display: inline-block;
  padding: 25px 40px 25px 0;
  margin-right: 50px;
  text-transform: uppercase;

  ${linkStyle};

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
  }
`

const IndexList = styled.ul`
  border-top: ${props => props.theme.border};
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
`

const IndexItem = styled.li`
  list-style: none;
  border-bottom: ${props => props.theme.border};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  max-width: 1340px;
  width: 100%;

  & + ${() => IndexItem} {
    margin-top: 12px;
  }

  * {
    color: ${props => props.color} !important;
    border-color: ${props => props.color} !important;
    stroke: ${props => props.color} !important;
  }
`

const IndexLinkCarnet = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    180deg,
    rgba(3, 5, 0, 0.79) 0%,
    rgba(3, 5, 0, 0.6) 20%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 100;
  transition: background-color 500ms;

  &:hover {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(146, 150, 152, 0.1) 75%,
      rgba(75, 80, 72, 0.1) 100%
    );
  }
`

const IndexDatasheetLink = styled.a`
  position: relative;

  display: inline-block;
  height: 20px;
  line-height: 20px;
  margin-top: 20px;
  padding-right: 42px;
  margin-right: 60px;
  margin-left: 20px;

  text-transform: uppercase;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    margin-right: 20px;
    margin-left: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    margin-right: 0;
    margin-left: 0;
  }

  ${linkStyle};

  svg {
    position: absolute;
    right: 0;
    top: 54%;
    transform: translateY(-55%);
    width: 32px;
    height: 32px;
  }
`

const IndexItemHeader = styled.header`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 70px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    padding-bottom: 50px;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 0;
  }

  h2 {
    font-size: ${props => props.theme.fontSizes.large.subtitle};
    letter-spacing: 1px;
    font-weight: normal;
    font-family: "Institut De France";
    text-transform: uppercase;
    margin: 0;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      font-size: ${props => props.theme.fontSizes.medium.subtitle};
    }
  }
  a {
    text-decoration: none;
  }
  p {
    font-size: ${props => props.theme.fontSizes.large.description};
    margin-top: 7px;
    margin-bottom: 0;

    @media (max-width: ${props => props.theme.breakpoints.large}) {
      font-size: ${props => props.theme.fontSizes.medium.description};
    }
  }
`

const IndexPreview = styled.div`
  position: relative;
  height: 202px;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    height: 180px;
  }

  video {
    object-fit: cover;
    display: block;
    height: 100%;
  }
`

const IndexPage = ({ data }) => {
  const { activeLoading, stopLoading } = useContext(LoadingContext)
  const [carnets] = useState(() =>
    data.allMarkdownRemark.nodes.map(({ frontmatter }) => frontmatter)
  )

  useEffect(() => {
    if (activeLoading) stopLoading()
  }, [activeLoading, stopLoading])
  carnets.map(c => console.log())
  return (
    <IndexContainer>
      <IndexHeader>
        <Title>Léonard De Vinci. Les carnets de l'institut</Title>
      </IndexHeader>
      <IndexMenu>
        <IndexMenuButton
          href="https://www.institutdefrance.fr/lesdossiers/leonard-de-vinci-carnets"
          target="_blank"
        >
          <span>Vidéo de présentation</span>
          <DatasheetIcon />
        </IndexMenuButton>
        <IndexMenuButton
          href="https://www.institutdefrance.fr/wp-content/uploads/2020/08/Fiche-biographique-de-L%C3%A9onard-de-Vinci.pdf"
          target="_blank"
        >
          <span>Fiche biographique de Léonard</span>
          <DatasheetIcon />
        </IndexMenuButton>
      </IndexMenu>
      <IndexMain title={true} as={"main"}>
        <IndexList>
          {carnets.map(c => (
            <IndexItem key={c.name} color={c.color}>
              <IndexItemHeader>
                <div>
                  <h2>
                    <Link to={`/carnet-${c.name}`}>Carnet {c.name}</Link>
                  </h2>
                  <p>
                    PÉRIODE DE RÉDACTION — <span>{c.date}</span>
                  </p>
                  <p>
                    LIEU DE CONSERVATION ET COTE —<span>{c.place}</span>
                  </p>
                </div>
                <IndexDatasheetLink
                  color={c.color}
                  href={c.datasheet}
                  target="_blank"
                >
                  <span style={{ whiteSpace: "nowrap" }}>
                    Fiche élève à télécharger
                  </span>
                  <DatasheetIcon />
                </IndexDatasheetLink>
              </IndexItemHeader>
              <IndexPreview>
                <IndexLinkCarnet to={`/carnet-${c.name}`}>
                  <img src={CarnetIcon} alt="" />
                </IndexLinkCarnet>
                <video
                  poster={c.placeholder.img.childImageSharp.thumb.src}
                  loop
                  autoPlay
                  muted
                >
                  <source
                    src={c.placeholder.webm.publicURL}
                    type="video/webm"
                  />
                  <source src={c.placeholder.mp4.publicURL} type="video/mp4" />
                </video>
              </IndexPreview>
            </IndexItem>
          ))}
        </IndexList>
      </IndexMain>
    </IndexContainer>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query Index {
    allMarkdownRemark(sort: { fields: frontmatter___name, order: ASC }) {
      nodes {
        id
        frontmatter {
          title
          name
          format
          material
          date
          place
          color
          placeholder {
            mp4 {
              publicURL
            }
            webm {
              publicURL
            }
            img {
              childImageSharp {
                thumb: resize(width: 360, height: 202, quality: 75) {
                  src
                }
              }
            }
          }
          datasheet
        }
      }
    }
  }
`
